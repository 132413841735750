<template>
    <nav class="w-64 flex flex-col bg-white ">
        <div class="flex-grow">
            <router-link 
                :to="{ name: 'dashboard', params: {accountId} }"
                class="h-32 flex items-center justify-center mb-6">
                
                <img 
                    src="/assets/logo_dark.svg"
                    class="object-contain p-4"
                />
            </router-link>
            
            <router-link 
                :to="{ name: 'dashboard', params: {accountId} }"
                class="item">
                
                <i class="fas fa-chart-line" />
                {{ $t('navigation.dashboard') }}
            </router-link>
            
            <router-link 
                :to="{ name: 'products', params: {accountId} }"
                class="item">
                
                <i class="fas fa-boxes" />
                {{ $t('navigation.products') }}
            </router-link>
            
            <router-link 
                :to="{ name: 'customers', params: {accountId} }"
                class="item">
                
                <i class="fas fa-users" />
                {{ $t('navigation.customers') }}
            </router-link>
            
            <router-link 
                :to="{ name: 'offers', params: {accountId} }"
                class="item">
                
                <i class="fas fa-file-medical-alt" />
                {{ $t('navigation.offers') }}
            </router-link>
            
            <!-- <router-link 
                :to="{ name: 'learn', params: {accountId} }"
                class="item">
                
                <i class="fas fa-lightbulb" />
                {{ $t('navigation.learnMore') }}
            </router-link> -->
            
        </div>
        
        <router-link 
            :to="{ name: 'settings', params: {accountId} }"
            class="item flex-none">
            
            <i class="fas fa-user-cog" />
            {{ $t('navigation.settings') }}
        </router-link>
        
        <div 
            v-if="getUser"
            class="flex-none m-4 text-center">
            
            <div>
                <router-link 
                    :to="{ name: 'accounts', params: {accountId} }"
                    v-if="getUser.signInUserSession.idToken.payload['cognito:groups'] && getUser.signInUserSession.idToken.payload['cognito:groups'].length > 1"
                    class="link">
                    
                    {{ getUser.attributes.email }}
                </router-link>
                <span v-else>
                    {{ getUser.attributes.email }}
                </span>
            </div>
            
            <button 
                @click="signOut()"
                class="hover:text-black">
                {{ $t('auth.signOut') }}
            </button>
        </div>
    </nav>
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';
    
    export default {
        mixins: [
            AuthMixins,
        ],
        
        computed: {
            getUser(){
                return this.$store.getters.getUser;
            },
            getAccount(){
                return this.$store.getters.getAccount;
            }
        },
    }
</script>

<style lang="scss" scoped>
    nav {
        .item {
            @apply flex;
            @apply items-center;
            @apply mx-8;
            @apply mt-2;
            @apply p-4;
            @apply rounded-lg;
            
            i {
                @apply w-8;
                @apply h-8;
                @apply mx-4;
                @apply text-xl;
                @apply flex;
                @apply items-center;
                @apply justify-center;
            }
            
            &:hover:not(.router-link-active) {
                @apply bg-gray-200;
            }
            
            &.router-link-active {
                @apply text-white;
                @apply bg-cyan;
            }
            
        }
    }
</style>
